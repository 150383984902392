import { h } from "preact";
import { observer } from "mobx-preact";
import cx from "classnames";
import "./index.less";
import { isMobile } from "../../utils/device-utils";

const prefix = isMobile() ? "xfe-common-login-toast-mobile" : "xfe-common-login-toast";

const Toast = ({ store }) => {
  store.use();
  // id="__XFE_LOGIN_TOAST__"
  return (<div className={cx(`${prefix}-toast`, store?.visible && `${prefix}-show`)}>
    <div className={`${prefix}-toastMsg`}>{store?.msg}</div>
  </div>);
};

export default observer(Toast);
