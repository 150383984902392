import {observable, action, computed} from "mobx";
import captchaHandlerDecorate from "../../utils/captcha-deco-util";
import {CAPTCHA_PRE_AUTH, ONE_KEY_ADDRESS, XOYO_REGISTER} from "../../constants/urls";
import Services from "../../services";
import SmsCodeInputStore from "../sms-code-input/store";
import TinyInputStore from "../tiny-input/store";
import ToastStore from "../toast/store";
import { useEffect, useRef } from "preact/hooks";
import rsa from "@xfe/rsa-zt";
import { isMobile } from "../../utils/device-utils";
import { phoneNumber } from "../../utils/common-regex";
import lockAsync from "../../utils/lock-async";
import BindPhoneListStore from "../bind-phone-list/store";
import {ContactwayCookieSpaceName} from "../../constants";
import BindPhoneTipStore from "../bind-phone-tip/store";
import SendCodeFaqStore from "../send-code-faq/store";
import CodeExceptionTipStore from "../code-exception-tip/store";
import {delay} from "../../utils/delay";


class LoginStore {
  constructor({ onChangeContent, onClose, ...rest }) {
    this.onChangeContent = (type) => {
      const isRedirectRegister = window.XPASS.isRedirectRegister;
      if (isRedirectRegister  /* 跳转xoyo链接去注册 */) {
        isMobile() ? window.open(XOYO_REGISTER) : onChangeContent(type);
      } else {
        onChangeContent(type);
      }
    };
    this.onClose = onClose;
    this.restProps = rest;
  }

  //登录配置
  @observable
  loginConfig = window.XPASS.loginConfig;

  //手机登录
  smsCodeInput = new SmsCodeInputStore();

  //账号框
  accountInputStore = new TinyInputStore();

  //密码框
  passwordInputStore = new TinyInputStore();

  //toast
  toast = new ToastStore();

  //绑定的用户手机号码列表
  bindPhoneListStore = new BindPhoneListStore();

  //未绑定手机号提示
  bindPhoneTipStore = new BindPhoneTipStore();

  //收不到验证码faq
  sendCodeFaqStore = new SendCodeFaqStore();

  //验证码异常
  codeExceptionTipStore = new CodeExceptionTipStore();

  /**
   * 是否已经同意了协议
   * @type {boolean}
   */
  @observable hasAgreed = false;
  //toast内容
  @observable
  toastContent = {
    msg: ""
  };

  //当前登录方式 passport:金山通行证 phone:手机号码
  @observable currentTab = "passport";

  //客户端标识
  @observable client = window.XPASS.loginConfig?.passportLoginType?.client ||'default';


  //金山通行证登录框配置
  @observable
  accountMsgConfig = [
    {
      key: "account",
      type: "text",
      placeholder: "账号/手机/邮箱",
      store: this.accountInputStore,
      onBlur: () => {
        this.account();
      }
    }, {
      key: "password",
      type: "password",
      placeholder: "密码",
      store: this.passwordInputStore,
      onBlur: () => {
        this.password();
      }
    }
  ];


  //是否锁定登录按钮
  @observable isLockLogin = false;

  //是否在加载极验
  @observable loading = false;

  // ProtocolGroup dom节点是否正在抖动 用于强调提示用户
  @observable isProtocolGroupDomShaking = false;
  /**
   * 登录成功后回调
   */
  @observable
  onLoginSuccess = window.XPASS.onLoginSuccess;

  @observable
  themeImgs = window?.XPASS?.themeImgs;

  /**
   * "其他方式登录"字样是否显示
   * @type {boolean}
   */
  @observable
  otherLoginTypeTextVisible = false;

  //显示绑定手机提示
  /**
   * 弹窗内容类型
   * 1:登录
   * 2:显示绑定手机提示
   * 3:显示绑定手机列表
   * 4:收不到验证码faq
   * 5:验证码异常
   */
  @observable modalType = 1;

  //cookie存储的结果
  @observable cookieResult = {};

  //选择的手机号的contactway
  @observable contactway;

  /**
   * 更新弹窗内容类型
   * @param type
   */
  onChangeModalType = (type) =>{
    this.modalType = type;
  }

  //登录方式切换
  @action.bound
  onTabClick(tab) {
    this.currentTab = tab;

    //当在逍遥登录输入手机号时, 这时候点击去手机登录. 自动填入手机号码
    if (tab === "phone") {
      if (phoneNumber.test(this.accountInputStore.value)) {
        this.smsCodeInput.tinyInputPhoneNum.value = this.accountInputStore.value;
      }
    }
  }

  /**
   * 更新警告提示
   * @param type
   * @param msg
   */
  setWarnTip(type, msg) {
    this.accountMsgConfig = this.accountMsgConfig.map((item) => item.key === type ? {
      ...item,
      warnTip: msg
    } : item);

  }

  /**
   * 账号
   * @param value
   */
  account() {
    if (!this.accountInputStore.value) {
      this.setWarnTip(this.accountMsgConfig[0].key, "请输入通行证账号");
      return;
    }
    this.setWarnTip(this.accountMsgConfig[0].key, "");
    return this.accountInputStore.value;
  }


  /**
   * 密码
   */
  password() {
    if (!this.passwordInputStore.value) {
      this.setWarnTip(this.accountMsgConfig[1].key, "请输入密码");
      return;
    }
    this.setWarnTip(this.accountMsgConfig[1].key, "");
    return this.passwordInputStore.value;
  }

  /**
   * 勾选"同意协议"时进行回调, 同步store的hasAgreed状态
   * @param e
   */
  @action.bound
  onAgreeCheckBoxChange(e) {
    this.hasAgreed = e.target.checked;
  }

  /**
   * 用于抖动ProtocolGroupDom节点 提示用户
   */
  @action.bound
  shakeAgreeProtocolGroupDom() {
    if (this.isProtocolGroupDomShaking) {
      return;
    }
    this.isProtocolGroupDomShaking = true;
    /**
     * 两秒后恢复正常
     */
    setTimeout(() => {
      this.isProtocolGroupDomShaking = false;
    }, 2000);
  }

  /**
   * 点击立即登录
   * @returns {Promise<void>}
   */
  @action.bound
  async onLoginClick(e) {
    e.preventDefault();

    if (!this.hasAgreed/*优先校验是否已经勾选已阅读并同意协议*/) {
      this.toastContent = { msg: "请仔细阅读并同意隐私权政策和服务使用协议" };
      this.shakeAgreeProtocolGroupDom();
      return;
    }

    if (this.loading) {
      return;
    }

    if (this.currentTab === "passport" /* 金山通行证登录 */) {

      if (!this.account()) {
        this.accountInputStore.elementRef?.current?.focus();
      } else if (!this.password()) {
        this.passwordInputStore.elementRef?.current?.focus();
      } else {
        this.loading = true;
        !this.isLockLogin && await this.onPassportLogin().catch(err => {
          this.loading = false;
          console.error(err.msg);
        });
      }
      this.loading = false;

    } else if (this.currentTab === "phone" /* 手机登录 */) {
      if (!this.smsCodeInput.phoneNum()) {
        this.smsCodeInput.tinyInputPhoneNum?.elementRef?.current?.focus();
      } else if (!this.smsCodeInput.phoneCode()) {
        this.smsCodeInput.tinyInputPhoneCode?.elementRef?.current?.focus();
      } else {
        !this.isLockLogin && await this.onLoginByCodeClick();
      }
    }

  }

  /**
   * 金山通行证登录
   * @param account
   * @param password
   * @param authParams
   * @returns {Promise<void>}
   */
  @action.bound
  @lockAsync()
  onPassportLogin() {
    this.toastContent = { msg: "加载中" };
    return this.loginClick();
  }

  @captchaHandlerDecorate()
  async loginClick({ ...authParams }) {
    try {
      this.toast.onClose();
      this.isLockLogin = true;
      const rsaParams = rsa.markRSAAndEncryptParamField({
        account: this.account(),
        password: this.password()
      }, ["password"]);
      await Services.login({ ...rsaParams, ...authParams, ...this.restProps }).then((res) => {
        // console.info('login:', res);
        this.isLockLogin = false;
        this.toastContent = { msg: "登录成功" };
        window.XPASS.$$tracker.loginSuccess();
        setTimeout(async () => {
          this.onLoginSuccess && this.onLoginSuccess({ account: res?.account, res });
          await delay(1400);
          this.onClose && this.onClose();
        }, 600);

      }).catch(err => {
        // console.info(err);
        this.isLockLogin = false;
        if (err?.code !== -20199 /* 密码安全提示 */) {
          this.toastContent = { msg: err.message };
        }
        window.XPASS.$$tracker.loginFail(err?.code, err?.message);
      });
    } catch (err) {
      console.log("loginClick err:", err);
    }
  }

  /**
   * 手机验证码登录
   * @returns {Promise<void>}
   */
  @action.bound
  @lockAsync()
  async onLoginByCodeClick() {
    this.isLockLogin = true;
    const params = {
      account: this.smsCodeInput.phoneNum(),
      client: this.client,
      code: this.smsCodeInput.phoneCode(),
      contactway:this.contactway
    };
    await Services.loginByCode(params).then((res) => {
      this.isLockLogin = false;
      const suffix = res.special_account_suffix;
      this.toastContent = { msg:  suffix ? `金山通行证：${this.smsCodeInput.phoneNum()}_${suffix}，登录成功!`:"登录成功" };
      this.setContactway(this.contactway)
      setTimeout(async () => {
        this.onLoginSuccess && this.onLoginSuccess({ account: res?.account, res });
        await delay(1400);
        this.onClose && this.onClose();
      }, 600);

    }).catch(err => {
      this.isLockLogin = false;
      this.toastContent = { msg: err.message };
    });
  }

  /**
   * wegame登录
   * @param platform
   * @returns {Promise<void>}
   */
  @action.bound
  async getAuthorizeUrl(platform, game) {
    if(platform === "onekey"){
      return window.location.href=`${ONE_KEY_ADDRESS}?redirect-url=${encodeURIComponent(window.location.href)}`
    }
    if (!this.hasAgreed/*优先校验是否已经勾选已阅读并同意协议*/) {
      this.toastContent = { msg: "请仔细阅读并同意隐私权政策和服务使用协议" };
      this.shakeAgreeProtocolGroupDom();
      return;
    }
    if (platform === "wegame") {
      if (window.XPASS.wegameCustomLoginFunction) {
        window.XPASS.wegameCustomLoginFunction();
        this.onClose && this.onClose();
      } else {
        await Services.getWegameAuthorizeUrl({
          platform: platform,
          redirect: window.location.href,
          game: game
        }).then(res => {
          if (res?.authorize_url) {
            window.location.href = res?.authorize_url;
          }
        }).catch(err => {
          // console.info('url', err);
          this.toastContent = { msg: err.message };
        });
      }
    } else {
      await Services.getThirdAuthorize(platform).then(res => {
        if (res?.auth_url) {
          window.location.href = res?.auth_url;
        }
      }).catch(err => {
        // console.info('thirdUrl:', err);
        this.toastContent = { msg: err.message };
      });
    }

    if (platform === "wegame") {
      window.XPASS.$$tracker.wegameClick();
    } else if (platform === "qq") {
      window.XPASS.$$tracker.qqClick();
    }

  }

  getContactway = () =>{
    const cookies = document.cookie.split(";"); // 将所有cookie拆分成一个数组
    cookies.forEach(cookie => {
      const [name, value] = cookie.trim().split("="); // 获取每个cookie的名称和值
      if (name === `${ContactwayCookieSpaceName}`) {
       let result =  JSON.parse(value)
        this.cookieResult = result;
        this.contactway = result[this.smsCodeInput.tinyInputPhoneNum.value]||"";
      }
    });
  }

  /**
   * 存储多手机号码的账号选择发送验证码的手机号码
   * @param contactway
   */
  setContactway = (contactway) => {
    const now = new Date();
    const expireDate = new Date(now.getFullYear(), now.getMonth()+1, now.getDate()); // 设置过期日期为1个月后
    let result = this.cookieResult;
    result[this.smsCodeInput.tinyInputPhoneNum.value] = contactway;

    document.cookie = `${ContactwayCookieSpaceName}=${JSON.stringify(result)};expires=${expireDate.toUTCString() };path=/`;

  }

  removeContactway = () => {
    this.contactway="";
    this.smsCodeInput.maskedContactway=""
    if(this.cookieResult[this.smsCodeInput.tinyInputPhoneNum.value]){
      this.setContactway("");
    }

    // document.cookie = `${ContactwayCookieSpaceName} =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  use() {

    this.accountInputStore.elementRef = useRef();
    this.passwordInputStore.elementRef = useRef();
    this.smsCodeInput.tinyInputPhoneNum.elementRef = useRef();
    this.smsCodeInput.tinyInputPhoneCode.elementRef = useRef();


    useEffect(() => {
      this.smsCodeInput.type = "login";

      this.bindPhoneListStore.onChangeModalType = this.onChangeModalType;
      this.smsCodeInput.onChangeModalType=this.onChangeModalType;
      this.sendCodeFaqStore.onChangeModalType = this.onChangeModalType;
      this.bindPhoneTipStore.onChangeModalType = this.onChangeModalType;
      this.codeExceptionTipStore.onChangeModalType = this.onChangeModalType;
      this.bindPhoneTipStore.onTabClick = this.onTabClick;

      this.bindPhoneListStore.confirmCallback = ()=> {this.smsCodeInput.onSendCodeClick()};

      this.smsCodeInput.removeContactway=this.removeContactway;

      this.smsCodeInput.getContactway = this.getContactway;

    }, []);

    useEffect(() => {
      if (this.toastContent.msg) {
        this.toast.visible = true;
        this.toast.msg = this.toastContent.msg;

      }
    }, [this.toastContent]);

    useEffect(() => {
      if (this.loginConfig) {
        this.currentTab = this.loginConfig?.passportLoginType?.defaultType;
      }
    }, [this.loginConfig]);

    /**
     * 更新显示"其它方式登录"
     */
    useEffect(() => {
      if (this.loginConfig.thirdLoginType) {
        this.loginConfig.thirdLoginType?.map((item) => {
          if (item.enable) {
            this.otherLoginTypeTextVisible = true;
          }
        });
      }
    }, [this.loginConfig.thirdLoginType]);

    useEffect(()=>{
      if(this.smsCodeInput.phoneNumberList && this.smsCodeInput.phoneNumberList?.length>0){
        this.bindPhoneListStore.phoneNumberList = this.smsCodeInput.phoneNumberList;
      }
    },[this.smsCodeInput.phoneNumberList])

    useEffect(()=>{
      if(this.bindPhoneListStore.selectContactway){
        this.contactway=this.bindPhoneListStore.selectContactway;
      }

    },[this.bindPhoneListStore.selectContactway])

    useEffect(()=>{
      this.smsCodeInput.contactway = this.contactway;
    },[this.contactway])

    useEffect(()=>{
      if(this.smsCodeInput.tinyInputPhoneNum.value){
        this.contactway = "";
        this.smsCodeInput.maskedContactway="";
        this.bindPhoneListStore.account=this.smsCodeInput.tinyInputPhoneNum.value;
        this.bindPhoneTipStore.account= this.smsCodeInput.tinyInputPhoneNum.value;

      }
    },[this.smsCodeInput.tinyInputPhoneNum.value])

    useEffect(()=>{
      const checkBoxEl = document.getElementById('agreeCheckBoxInput');
      if(this.hasAgreed /* 同意协议 */){
        if(checkBoxEl){
          checkBoxEl.checked = true;
        }
      }else{
        if(checkBoxEl){
          checkBoxEl.checked = false;
        }
      }
    },[this.hasAgreed])
  }
}

export default LoginStore;
