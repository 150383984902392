import { h, Fragment } from "preact";
import { observer } from "mobx-preact";
import cx from "classnames";
import SmsCodeInput from "../sms-code-input";
import BindPhoneList from '../bind-phone-list';
import TinyInput from "../tiny-input";
import Toast from "../toast";
import "./index.less";
import Link from "../link";
import { isIE9, isMobile } from "../../utils/device-utils";
import BindPhoneTip from "../bind-phone-tip";
import SendCodeFaq from "../send-code-faq";
import CodeExceptionTip from "../code-exception-tip";



const  renderLogin = ({ store, className }) =>{
  const prefix = isMobile() ? "xfe-common-login-mobile" : "xfe-common-login";

  return <div className={cx(`${prefix}-main`, className)}>

    <div className={`${prefix}-loginWrap`}>
      <div className={`${prefix}-loginTabGroup`}>

        {store?.loginConfig?.passportLoginType?.typesConfig?.length > 0 && store?.loginConfig?.passportLoginType?.typesConfig?.map((item, index) => {
          if (item.type === "passport" && item.enable) {
            return <div className={cx(`${prefix}-tabText`, store?.currentTab === "passport" && `${prefix}-tabActive`, `${prefix}-loginTab`, `${prefix}-link`)} onClick={() => {
              store.onTabClick("passport");
            }}>金山通行证
              {store?.currentTab === "passport" && <div className={`${prefix}-borderBtmLogin`}/>}
            </div>;
          } else if (item.type === "phone" && item.enable) {
            return <div className={cx(`${prefix}-tabText`, store?.currentTab === "phone" && `${prefix}-tabActive`, `${prefix}-phoneLoginTab`, `${prefix}-link`)} onClick={() => {
              store.onTabClick("phone");
            }}>验证码登录
              {store?.currentTab === "phone" && <div className={`${prefix}-borderBtm`}/>}
            </div>;
          }
        })}

      </div>


      <form onSubmit={store?.onLoginClick}>
        {!isIE9() && <input type="submit" hidden/>}

        {store?.currentTab === "phone" &&
        <div className={`${prefix}-loginInputContent`}>
          <SmsCodeInput store={store?.smsCodeInput}/>
        </div>}

        {store?.currentTab === "passport" && <div className={`${prefix}-loginInputContent`}>
          {store?.accountMsgConfig?.length > 0 && store?.accountMsgConfig.map((item, index) => {
            return <TinyInput name={item.key} store={item.store} key={index} type={item.type} value={item.store.value} placeholder={item.placeholder} warnTip={item?.warnTip} onBlur={item?.onBlur}/>;
          })}
        </div>}

        {store?.currentTab === 'phone' && store.smsCodeInput?.maskedContactway &&
        <div className={`${prefix}-phoneTipGroup`}>
          <div className={`${prefix}-phoneTip`}>验证码发送至:{store?.smsCodeInput.maskedContactway}</div>
        </div>}

        <div className={`${prefix}-loginBtnGroup`}>
          <Link className={store?.themeImgs?.button ? `${prefix}-loginBtnImg` : `${prefix}-loginBtn`} style={store?.themeImgs?.button && { backgroundImage: `url(${store?.themeImgs?.button})` }} onClick={store.onLoginClick}>立即登录</Link>
          <div className={`${prefix}-otherBtn`}>
            <div className={`${prefix}-forgetBtnGroup`}>
              {store?.loginConfig?.forgetAccount?.enable && <>
                <Link href={store?.loginConfig?.forgetAccount?.url} target="_blank">忘记账号</Link>
                <span className={`${prefix}-separation`}>|</span>
              </>}

              { store?.currentTab === 'phone' ?
                <Link onClick={()=>{store.onChangeModalType(4)}}>收不到验证码？</Link>:
                <>{store?.loginConfig?.forgetPassword?.enable && <Link href={store?.loginConfig?.forgetPassword?.url} target="_blank">忘记密码</Link>}</>
              }
            </div>
            <Link className={`${prefix}-registerBtn`} onClick={() => {
              store?.onChangeContent(1);
            }}>注册>
            </Link>
          </div>
        </div>
        <div className={cx(`${prefix}-protocolGroup`, store.isProtocolGroupDomShaking && `${prefix}-protocolGroupDomShaking`)}>
          <input type="checkbox" id="agreeCheckBoxInput" className={`${prefix}-agreeCheckBoxInput`} onChange={store.onAgreeCheckBoxChange}/>
          <label htmlFor="agreeCheckBoxInput" className={`${prefix}-agreeCheckBoxLabel`}/>
          我已阅读并同意 <Link className={`${prefix}-protocolLink`} href={isMobile() ? "https://m.xoyo.com/#/protocol?id=4" : "https://help.xoyo.com/agreement?name=kingsoft-online-privacy-policy"} target={"_blank"}>《金山在线隐私权政策》</Link>
          <span className={`${prefix}-protocolLinkLine`}>|</span>
          <Link className={`${prefix}-protocolLink`} href={isMobile() ? "https://m.xoyo.com/#/protocol?id=1" : "https://help.xoyo.com/agreement?name=kingsoft-network-service-agreement"} target={"_blank"}>《金山网络服务使用协议》</Link>
        </div>
      </form>

      {store?.loginConfig?.thirdLoginType?.length > 0 && <div className={`${prefix}-loginFooter`}>
        {store.otherLoginTypeTextVisible && <div className={`${prefix}-footerTitle`}>
          <span className={`${prefix}-title`}>其他方式登录</span>
        </div>
        }
        <div className={`${prefix}-loginTypeGroup`}>
          {store?.loginConfig?.thirdLoginType?.map((item) => {
            if (item.enable) {
              if(item.type === 'onekey' && !isMobile()){
                return ;
              }
              return <Link className={`${prefix}-loginTypeItem`} key={item.type} onClick={() => {
                store?.getAuthorizeUrl(item.type, item.type === "wegame" ? item.game : "");
              }}>
                <div className={`${prefix}-loginTypeIcon`} style={item?.iconUrl && { backgroundImage: `url(${item?.iconUrl})` }}/>
                <div className={`${prefix}-loginTypeText`}>{item.text}</div>
              </Link>;

            } else {
              return null;

            }
          })}

        </div>
      </div>}

    </div>

    <Toast store={store?.toast}/>
  </div>;
}



const Login = ({ store, className }) => {
  store?.use();
  return <>
    {(store.modalType=== 1 || store.modalType===4) && renderLogin({ store, className })}
    {store.modalType===2 && <BindPhoneTip store={store.bindPhoneTipStore} />}
    {store.modalType===3 && <BindPhoneList store={store.bindPhoneListStore} />}
    {store.modalType===4 && <CodeExceptionTip store={store.codeExceptionTipStore} />}
    {store.modalType===5 && <SendCodeFaq store={store.sendCodeFaqStore} />}
  </>
};

export default observer(Login);

