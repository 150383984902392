import { observable, action } from "mobx";
import { useEffect } from "preact/hooks";

class ToastStore {
  @observable visible;

  @observable msg;

  @observable TOAST_TIMER;

  @action.bound
  onClose() {
    this.visible = false;
    this.msg = undefined;
    // const toastDom = document.getElementById("__XFE_LOGIN_TOAST__");
    // toastDom && toastDom?.parentNode?.removeChild(toastDom);
  }

  use() {
    useEffect(() => {
      return () => {
        clearTimeout(this.TOAST_TIMER);
      };
    }, []);

    useEffect(() => {
      if (this.visible) {
        if(this.TOAST_TIMER){
          clearTimeout(this.TOAST_TIMER);
        }
        this.TOAST_TIMER = setTimeout(() => {
          // this.visible = false;
          // this.msg = undefined;
          this.onClose();
        }, 2000);
      }

      // return () => {
      //   clearTimeout(this.TOAST_TIMER);
      // };
    }, [this.visible]);

  }

}

export default ToastStore;
