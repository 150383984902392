import { observable, action } from "mobx";
import SmsCodeInputStore from "../sms-code-input/store";
import TinyInputStore from "../tiny-input/store";
import { usernameRegex, chineseName, chineseId } from "../../utils/common-regex";
import Services from "../../services";
import ToastStore from "../toast/store";
import { useEffect, useRef } from "preact/hooks";
import rsa from "@xfe/rsa-zt";
import lockAsync from "../../utils/lock-async";
import { isMobile } from "../../utils/device-utils";
import { XOYO_REGISTER, XOYO_SIGNUP } from "../../constants/urls";
import scriptCreate from "../../utils/script-create";
import isMaster from "../../constants/is-master";

class RegisterStore {
  //切换弹窗内容
  constructor({ onChangeContent, ...rest } = {}) {
    this.onChangeContent = (type) => {
      onChangeContent(type);
    };
    this.restProps = rest;
  }

  //其它方式注册跳转链接
  registerLink = isMobile() ? XOYO_REGISTER : XOYO_SIGNUP;

  @observable
  onChangeContent;


  //手机号码框
  smsCodeInput = new SmsCodeInputStore();

  //账号框
  usernameStore = new TinyInputStore();

  //密码框
  passwordStore = new TinyInputStore();

  //核对密码框
  passwordCheckStore = new TinyInputStore();

  //真实姓名框
  realNameStore = new TinyInputStore();

  //身份证框
  identityNumStore = new TinyInputStore();

  //toast
  toast = new ToastStore();

  //toast内容
  @observable
  toastContent = {
    msg: ""
  };


  //账号相关信息框配置
  @observable
  registerMsgConfig = [
    {
      key: "username",
      type: "text",
      placeholder: "4-18位字符（首字符必须为字母）",
      maxlength: 18,
      store: this.usernameStore,
      onBlur: () => {
        this.username();
      }
    }, {
      key: "password", type: "password", placeholder: "密码", store: this.passwordStore, onBlur: () => {
        this.password();
      }
    }, {
      key: "passwordCheck", type: "password", placeholder: "确认密码", store: this.passwordCheckStore, onBlur: () => {
        this.passwordCheck();
      }
    }
  ];

  //真实信息框配置
  @observable
  identityMsgConfig = [
    {
      key: "realName", type: "text", placeholder: "真实姓名", store: this.realNameStore, onBlur: () => {
        this.realName();
      }
    }, {
      key: "identityNum", type: "text", placeholder: "身份证", store: this.identityNumStore, onBlur: () => {
        this.identity();
      }
    }
  ];

  //同意勾选
  @observable
  agreeStatus = false;

  //同意勾选提示显示情况
  @observable
  agreementTip = false;

  REGISTER_TARGET = "xpass_common_signup";

  REGISTER_REFER = "";

  REGISTER_CLIENT = window.XPASS.loginConfig?.passportLoginType?.client ||'default';

  //是否给注册按钮加锁
  isLockRegister = false;

  onRegisterSuccess = window.XPASS.onRegisterSuccess;

  @observable
  themeImgs = window?.XPASS?.themeImgs;

  /**
   * 更新警告提示
   * @param type
   * @param msg
   */
  setWarnTip(type, msg) {
    this.registerMsgConfig = this.registerMsgConfig.map((item) => item.key === type ? {
      ...item,
      warnTip: msg
    } : item);
  }

  setIdWarnTip(type, msg) {
    this.identityMsgConfig = this.identityMsgConfig.map((item) => item.key === type ? {
      ...item,
      warnTip: msg
    } : item);
  }

  /**
   * 注册账号关键字
   * @type {{contain: string, start: string, end: string}}
   */
  @observable
  usernameRule = {
    start: "",
    end: "",
    contain: ""
  };

  /**
   * 校验用户名
   */
  username() {
    if (!this.usernameStore.value) {
      return this.setWarnTip(this.registerMsgConfig[0].key, "通行证账号不能为空");
    } else if (!usernameRegex.test(this.usernameStore.value)) {
      return this.setWarnTip(this.registerMsgConfig[0].key, "通行证账号格式不正确");
    } else if (new RegExp(`^(${this.usernameRule.start})`, "i").test(this.usernameStore.value)) {
      return this.setWarnTip(this.registerMsgConfig[0].key, "账号开头不符合规范");
    } else if (new RegExp(`(${this.usernameRule.contain})`, "i").test(this.usernameStore.value)) {
      return this.setWarnTip(this.registerMsgConfig[0].key, "账号包含了非法字符");
    } else if (new RegExp(`(${this.usernameRule.end}$)`, "i").test(this.usernameStore.value)) {
      return this.setWarnTip(this.registerMsgConfig[0].key, "账号结尾不符合规范");
    }
    this.setWarnTip("username", "");
    return this.usernameStore.value;
  }


  /**
   * 校验密码
   */
  password() {
    if (!this.passwordStore.value) {
      return this.setWarnTip(this.registerMsgConfig[1].key, "密码不能为空");
    } else if (this.passwordStore.value.length < 8 || this.passwordStore.value.length > 32) {
      return this.setWarnTip(this.registerMsgConfig[1].key, "密码长度为8-32位字符");
    } else if (this.passwordStore.value === this.usernameStore.value) {
      return this.setWarnTip(this.registerMsgConfig[1].key, "密码和账号不能相同");
    } else if (!/(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S+$/.test(this.passwordStore.value)) {
      return this.setWarnTip(this.registerMsgConfig[1].key, "密码至少包含字母、数字、符号中的两种字符");
    }
    this.setWarnTip(this.registerMsgConfig[1].key, "");
    return this.passwordStore.value;
  }

  /**
   * 校验确认密码
   */
  passwordCheck() {
    if (!this.passwordCheckStore.value) {
      return this.setWarnTip(this.registerMsgConfig[2].key, "请确认您的密码");
    } else if (this.passwordCheckStore.value !== this.passwordStore.value) {
      return this.setWarnTip(this.registerMsgConfig[2].key, "两次输入密码不一致");
    }
    this.setWarnTip(this.registerMsgConfig[2].key, "");
    return this.passwordCheckStore.value;
  }

  /**
   * 同意协议
   * @returns {boolean}
   */
  agreement() {
    if (!this.agreeStatus) {
      return this.agreementTip = true;
    }
    this.agreementTip = false;
    return this.agreeStatus;
  }


  /**
   * 真实姓名
   */
  realName() {
    if (!this.realNameStore.value) {
      return this.setIdWarnTip(this.identityMsgConfig[0].key, "真实姓名不能为空");
    } else if (!chineseName.test(this.realNameStore.value)) {
      return this.setIdWarnTip(this.identityMsgConfig[0].key, "姓名为2-8个汉字");
    }
    this.setIdWarnTip(this.identityMsgConfig[0].key, "");
    return this.realNameStore.value;
  }

  /**
   * 身份证
   */
  identity() {
    if (!this.identityNumStore.value) {
      return this.setIdWarnTip(this.identityMsgConfig[1].key, "身份证号码不能为空");
    } else if (!chineseId.test(this.identityNumStore.value)) {
      return this.setIdWarnTip(this.identityMsgConfig[1].key, "请正确填写身份证号码");
    }
    this.setIdWarnTip(this.identityMsgConfig[1].key, "");
    return this.identityNumStore.value;
  }

  /**
   * 点击勾选阅读同意协议
   */
  @action.bound
  onAgreeClick() {
    this.agreeStatus = !this.agreeStatus;
  }

  /**
   * 剑网3全域下注册成功进行埋点上报
   */
  jx3RegisterTracker(account){
    let isReport = isMaster ? window.XPASS.isReportJX3Register : window.XPASS.isReportJX3RegisterInTest;
    if(isReport){
      if(isMaster){
        if(window.location.host === 'jx3.xoyo.com'){
          __ozEvent('registerok', `ID=${account}`);
        }
      }else{
        if((window.location.pathname).indexOf('jx3.xoyo.com') > -1 /* 测试环境下 */)
        __ozEvent('registerok', `ID=${account}`);
      }
    }
  }

  /**
   *  'account': sup_act_v,
   'password': sup_pwd_v,
   'from': $_REFER,
   'target': $_TARGET,
   'more': 1,
   'name': sup_name_v,
   'id_number': sup_sfz_v,
   'mobile': sup_mobile_v,
   'code': sup_vde_v,
   'client': 'default'
   * @returns {Promise<void>}
   */
  @action.bound
  @lockAsync()
  async onRegisterClick(e) {
    e.preventDefault();

    if (!this.username()) {
      this.usernameStore.elementRef?.current?.focus();
    } else if (!this.password()) {
      this.passwordStore.elementRef?.current?.focus();
    } else if (!this.passwordCheck()) {
      this.passwordCheckStore.elementRef?.current?.focus();
    } else if (!this.smsCodeInput.phoneNum()) {
      this.smsCodeInput.tinyInputPhoneNum.elementRef?.current?.focus();
    } else if (!this.smsCodeInput.phoneCode()) {
      this.smsCodeInput.tinyInputPhoneCode.elementRef?.current?.focus();
    } else if (!this.realName()) {
      this.realNameStore.elementRef?.current?.focus();
    } else if (!this.identity()) {
      this.identityNumStore.elementRef?.current?.focus();
    } else if (!this.agreeStatus) {
      this.toastContent = { msg: "请先阅读并同意协议" };
    } else if (!this.isLockRegister) {
      const rsaPassword = rsa.markRSAAndEncryptParamField({ password: this.password() }, ["password"]);
      const params = {
        account: this.username(),
        form: this.REGISTER_REFER,
        target: this.REGISTER_TARGET,
        more: 1,
        name: this.realName(),
        id_number: this.identity(),
        mobile: this.smsCodeInput.phoneNum(),
        code: this.smsCodeInput.phoneCode(),
        client: this.REGISTER_CLIENT,
        ...rsaPassword,
        ...this.restProps
      };

      this.isLockRegister = true;
      await Services.register(params).then((result) => {
        // console.info(result);
        window.XPASS.$$tracker.regSuccess();
        this.jx3RegisterTracker(result?.account)
        setTimeout(() => {
          this.isLockRegister = false;
          this.onRegisterSuccess && this.onRegisterSuccess(result);
          this.onChangeContent(2);
        }, 500);
      }).catch(err => {
        this.isLockRegister = false;
        this.toastContent = { msg: err.message };
      });
    }


  }

  /**
   * 获取注册金山通行证账号关键词库
   * @returns {Promise<void>}
   */
  async getRuleConfig() {
    await Services.getRuleConfig({ scene: "register" }).then(res => {
      const { end, start, contain } = res;
      end.push("_");
      this.usernameRule.start = start.join("|");
      this.usernameRule.end = end.join("|");
      this.usernameRule.contain = contain.join("|");
    }).catch(err => {
      this.toastContent = { msg: err.message };
    });
  }

  use() {

    this.usernameStore.elementRef = useRef();
    this.passwordStore.elementRef = useRef();
    this.passwordCheckStore.elementRef = useRef();
    this.smsCodeInput.tinyInputPhoneNum.elementRef = useRef();
    this.smsCodeInput.tinyInputPhoneCode.elementRef = useRef();
    this.realNameStore.elementRef = useRef();
    this.identityNumStore.elementRef = useRef();

    useEffect(() => {
      this.getRuleConfig();
      this.smsCodeInput.type = "register";
      scriptCreate('//zhcdn01.xoyo.com/xassets/lib/751code/https/o_code.js');
    }, []);

    useEffect(() => {
      if (this.toastContent.msg) {
        this.toast.visible = true;
        this.toast.msg = this.toastContent.msg;

      }
    }, [this.toastContent]);

  }
}

export default RegisterStore;
