import jsonp from "../../utils/jsonp-util";
import jsonpPromise from 'jsonp-promise';

/**
 * 发送自定义配置接口
 * @param api - 获取数据接口，一般为官网
 * @param tag - 自定义配置的唯一标识
 * @return {Promise<*>}
 * prefix 如有必要可以设定为指定的命名空间
 * @example
 * makeCmsCustomConfigRequest('//www.xinhuozg.com/api/config/tag/', 'zt/download_config');
 *
 */
const makeCmsCustomConfigRequest = (api, tag) => {
  return jsonpPromise(`${api}${tag}?filter=0`, {
    prefix: '__code_cms_api'
  }).promise;
};

/**
 * 发送 CMS 请求
 * @param {string} [api] - 获取数据接口, 通常为: https://xxx.xoyo.com/api.php, xxx 可以为 jx3, js2, js 等
 * @param {string} [op] - 接口名称：search_api
 * @param {string} action - 方法名
 * @param {number} categoryId - 分类 ID
 * @param {object} [rest] - 其他参数
 */
const makeCmsRequest = ({ api = "https://jx3.xoyo.com/api.php", op = "search_api", action, categoryId, ...rest }) => {
  // noinspection SpellCheckingInspection
  return jsonp(api, {
    op,
    action,
    catid: categoryId,
    ...rest
  });
};

/**
 * @enum {SORT_BY}
 */
const SORT_BY = {
  ASC: "asc",
  DESC: "desc"
};

// noinspection SpellCheckingInspection
/**
 * @enum {SORT_BY}
 */
const ORDER_BY = {
  /**
   * 根据列表id排序
   */
  LIST_ORDER: "listorder",
  /**
   * 根据文章创建时间排序
   */
  INPUT_TIME: "inputtime",
  /**
   * 根据文章更新时间排序
   */
  UPDATE_TIME: "updatetime"
};

// noinspection SpellCheckingInspection
/**
 * 获取文章列表
 * @param {number} categoryId - 分类 ID, 例子: 2457
 * @param {number} pageNum - 页码, 注意这里的起始页冲 1 开始, 例子: 1
 * @param {number} pageSize - 分页条数, 例子: 10
 * @param {ORDER_BY} orderBy - 列表排序, 支持参数：listorder，文章创建时间：inputtime, 文章更新时间：updatetime，默认值：inputtime
 * @param {SORT_BY} sortBy - 支持参数：倒序：desc，顺序：asc，默认值：desc
 * @param {string} [api] - 获取数据接口, 通常为: https://xxx.xoyo.com/api.php, xxx 可以为 jx3, js2, js 等
 * @param moreinfo
 * @example
 * getArticleList({ categoryId: 2457, pageNum: 1, pageSize: 10 })
 * // output:
 * {
 *  "list": [
 *    {
 *      "articleId": "2175",
 *      "categoryId": "2458",
 *      "title": "《剑网3》助手APP3.16限封测 ",
 *      "thumb": "http://jx3.xoyo.com/uploadfile/2018/0314/20180314094537438.jpg",
 *      "keywords": "助手 内容 封测",
 *      "description": "第三届竞技大师赛正在火热筹备当中，为了让侠士们能够更好地投入战斗准备，《剑网3》助手APP将于3月16日开启首轮小规模测试。本次测试仅限   ",
 *      "url": "http://local-jx3.xoyo.com/show-2458-2175-1.html",
 *      "listorder": "0",
 *      "inputtime": "1520991275",
 *      "updatetime": "1520997720"
 *    },
 *    {
 *      "articleId": "2174",
 *      "categoryId": "2458",
 *      "title": "竞技大师赛本周开启 官方FAQ",
 *      "thumb": "http://jx3.xoyo.com/uploadfile/2018/0313/20180313103935775.jpg",
 *      "keywords": "大师赛",
 *      "description": "《剑网3》竞技大师赛将于3 16日(本周五)正式打响！全民争霸一触即发，热血竞技点燃激情三月！相较于前两届大师赛，本届在公平模式的基础上   ",
 *      "url": "http://local-jx3.xoyo.com/show-2458-2174-1.html",
 *      "listorder": "0",
 *      "inputtime": "1520908635",
 *      "updatetime": "1520909153"
 *    }
 *  ],
 *  "total": 1301
 * }
 */
const getArticleList = ({ categoryId, pageNum, pageSize = 10, orderBy = ORDER_BY.INPUT_TIME, sortBy = SORT_BY.DESC, api, moreinfo = 0 }) => {
  return makeCmsRequest({
    action: "get_article_list",
    categoryId,
    page: pageNum,
    num: pageSize,
    order_by: orderBy,
    sort_by: sortBy,
    api,
    moreinfo
  }).then((res) => {
    // noinspection SpellCheckingInspection
    const { list = [], total } = res?.data;
    return {
      list: list.map(({ id, catid, ...rest }) => {
        return {
          articleId: id,
          categoryId: catid,
          ...rest
        };
      }),
      total
    };
  });
};

// noinspection SpellCheckingInspection
/**
 * 获取文章详情
 * @param {number} categoryId - 分类 ID, 例子: 2457
 * @param {number} articleId - 文章 ID, 例子: 1
 * @param {string} [api] - 获取数据接口, 通常为: https://xxx.xoyo.com/api.php, xxx 可以为 jx3, js2, js 等
 *
 * @example
 * getArticleDetail({ categoryId: 2457, id: 1 })
 * // output:
 * {
 *    "id": "2174",
 *    "catid": "2458",
 *    "title": "竞技大师赛本周开启 官方FAQ",
 *    "thumb": "http://jx3.xoyo.com/uploadfile/2018/0313/20180313103935775.jpg",
 *    "keywords": "大师赛",
 *    "description": "《剑网3》竞技大师赛将于3 16日(本周五)正式打响！全民争霸一触即发，热血竞技点燃激情三月！相较于前两届大师赛，本届在公平模式的基础上   ",
 *    "url": "http://local-jx3.xoyo.com/show-2458-2174-1.html",
 *    "inputtime": "1520908635",
 *    "updatetime": "1520909153",
 *    "content": "<p style=\"text-indent: 2em\">《剑网3》竞技大师赛将于3.16日(本周五)正式打响！全民争霸一触即发，热血竞技点燃激情三月！相较于前两届大师赛，本届在公平模式的基础上革新赛制，并加入线下复活赛、表演赛等全新环节，为侠士带来空前绝后的视觉盛宴！</p>\r\n<p style=\"text-indent: 2em\">为了在第一时间解决玩家们对于本次赛事的多方疑问，助力侠士能够更好的前往&ldquo;拭剑园&rdquo;参与本次的名剑盛会，《剑网3》官方罗列了部分侠士们关心的问题进行了解答。</p>\r\n<p style=\"text-indent: 2em; text-align: center;\"><img src=\"http://jx3.xoyo.com/uploadfile/2018/0313/20180313103935775.jpg\" style=\"height: 2192px; width: 800px;\" /></p>\r\n<p style=\"text-indent: 2em\">除了精彩刺激的战斗体验外，《剑网3》还为诸位侠士准备了众多福利：名剑币，大师赛专属称号，永久限量版肩部挂件，海量通宝，更有152万基础奖池将随众筹注资持续增长，最高荣耀和奖励虚位以待，诚邀各位侠士倾情加盟！</p>\r\n<p style=\"text-indent: 2em\">若侠士对于本次大师赛还有其他疑问，欢迎在@剑网3赛事 微博下进行留言，我们将会在第一时间为您解答。</p>\r\n<p style=\"text-indent: 2em\">《剑网3》官网：jx3.xoyo.com</p>\r\n"
 * }
 */
const getArticleDetail = ({ categoryId, articleId, api }) => {
  return makeCmsRequest({
    action: "get_article_detail",
    categoryId,
    id: articleId,
    api
  });
};

// noinspection SpellCheckingInspection
/**
 * 获取最新的新闻列表数据
 * @param {[number]} categoryIds - 分类 ID 列表, 例子: [2458, 2461]
 * @param {number} [pageSize] - 需要返回数据的条数, 默认值为 10, 例子: 10
 * @param {boolean} [needTotal] - 是否需要返回对应的数据长度, 默认值为false, 只返回数据 不返回数据长度
 * @param {string} [api] - 获取数据接口, 通常为: https://xxx.xoyo.com/api.php, xxx 可以为 jx3, js2, js 等
 *
 * @example
 * getLatestArticleList({ categoryIds: [2458, 2461], pageSize: 10})
 *
 * // output(没有返回数据总数):
 *
 * [
 *   0: { articleId: "29", categoryId: "6169", title: "这也是一条测试内容25", style: "", thumb: "", … }
 *   1: { articleId: "28", categoryId: "6169", title: "这也是一条测试内容24", style: "", thumb: "", … }
 *   2: { articleId: "27", categoryId: "6169", title: "这也是一条测试内容23", style: "", thumb: "", … }
 *   3: { articleId: "26", categoryId: "6169", title: "这也是一条测试内容22", style: "", thumb: "", … }
 *   4: { articleId: "25", categoryId: "6169", title: "这也是一条测试内容21", style: "", thumb: "", … }
 *   5: { articleId: "24", categoryId: "6169", title: "这也是一条测试内容20", style: "", thumb: "", … }
 *   6: { articleId: "23", categoryId: "6169", title: "这也是一条测试内容19", style: "", thumb: "", … }
 *   7: { articleId: "22", categoryId: "6169", title: "这也是一条测试内容18", style: "", thumb: "", … }
 *   8: { articleId: "21", categoryId: "6169", title: "这也是一条测试内容17", style: "", thumb: "", … }
 *   9: { articleId: "20", categoryId: "6169", title: "这也是一条测试内容16", style: "", thumb: "", … }
 * ]
 *
 * // output(带有返回数据总数):
 *
 * {
 *  list: [
 *   0: { articleId: "29", categoryId: "6169", title: "这也是一条测试内容25", style: "", thumb: "", … }
 *   1: { articleId: "28", categoryId: "6169", title: "这也是一条测试内容24", style: "", thumb: "", … }
 *   2: { articleId: "27", categoryId: "6169", title: "这也是一条测试内容23", style: "", thumb: "", … }
 *   3: { articleId: "26", categoryId: "6169", title: "这也是一条测试内容22", style: "", thumb: "", … }
 *   4: { articleId: "25", categoryId: "6169", title: "这也是一条测试内容21", style: "", thumb: "", … }
 *   5: { articleId: "24", categoryId: "6169", title: "这也是一条测试内容20", style: "", thumb: "", … }
 *   6: { articleId: "23", categoryId: "6169", title: "这也是一条测试内容19", style: "", thumb: "", … }
 *   7: { articleId: "22", categoryId: "6169", title: "这也是一条测试内容18", style: "", thumb: "", … }
 *   8: { articleId: "21", categoryId: "6169", title: "这也是一条测试内容17", style: "", thumb: "", … }
 *   9: { articleId: "20", categoryId: "6169", title: "这也是一条测试内容16", style: "", thumb: "", … }
 * ],
 *  total: 10
 * }
 */
const getLatestArticleList = async ({ categoryIds, pageSize = 10, needTotal = false, api }) => {
  const categoryIdsParams = categoryIds.map(id => `${id}|1|${pageSize}`).join(",");
  const response = await makeCmsRequest({
    action: "get_merge_article_list",
    merge_id: categoryIdsParams,
    api
  });

  // noinspection JSUnresolvedVariable
  const list = Object.keys(response)
    .reduce((result, key) => [...result, ...response[key].list], [])
    .sort((a, b) => Number(b.inputtime) - Number(a.inputtime))
    .slice(0, pageSize);

  if (needTotal) {
    return { list: list, total: list.length };
  } else {
    return list;
  }
};

const cmsApi = {
  getArticleList,
  getArticleDetail,
  getLatestArticleList,
  makeCmsCustomConfigRequest,
  SORT_BY,
  ORDER_BY
};

export default cmsApi;
